/**
 * HOW TO:
 *  1. Create a new file env.js
 *  2. Copy contents of this file (env.example.js) to the new file
 *  2. Replace the constants with variables specific for your environment
 *
 * NOTE:
 *  env.js file should NEVER be uploaded to a version control platform (e.g, git)
 */

// export const HOST = 'localhost';

export const HOST = 'api.semahub.com';

export const PROTOCOL = HOST === 'localhost'? 'http': 'https';

export const IMAGE_HOST = "https://media.semahub.com"

export const UIHOST = HOST === 'localhost'? `${HOST}:8080`: `app.semahub.com`;

export const APIBASE_HOST = PROTOCOL=='http'?`${PROTOCOL}://${HOST}:5094/api/v1/`: `${PROTOCOL}://${HOST}/api/v1/`;



export const REDIRECT_URI = `${PROTOCOL}://${UIHOST}/#/finalize`;

export const SOCKET_HOST =HOST === 'localhost'? `${PROTOCOL}://${HOST}:8080`:`${PROTOCOL}://${HOST}`;

export const VOICE_API = HOST === 'localhost'? `${HOST}:5094`: `voice.semahub.com`;

