const USER_INFO_KEY = 'user_info';

const USER_LOGIN_CHECK_KEY = 'user_loggin_check';

const CURRENT_TOKEN_KEY = 'current_token';


function clearItems() {
    localStorage.clear();
  }
  
function getItem(key) {
    const existing = localStorage.getItem(key) || '{}'
    try {
      const value = JSON.parse(existing);
      return value;
    } catch (e) {
      // // outdisp('err data:' + e);
      return existing;
    }
  }

  function getItemSession(key) {
    const existing = sessionStorage.getItem(key) || null
    try {
      const value = JSON.parse(existing);
      return value;
    } catch (e) {
      // // outdisp('err data:' + e);
      return existing;
    }
  }

function saveItem(key, val) {
    if (typeof val === 'string') {
        localStorage[key] = val
    }
    else{
        localStorage[key] = JSON.stringify(val)
    }
  }


  function saveItemSession(key, val) {
    if (typeof val === 'string') {
        sessionStorage[key] = val
    }
    else{
        sessionStorage[key] = JSON.stringify(val)
    }
  }


export function isUserLoggedIn() {
    return Object.keys(getItem(USER_INFO_KEY)).length > 0;
}
  
export function isFirstLogIn(userid) {
    return getItem(USER_LOGIN_CHECK_KEY + '_' + userid) === null;
}

export function currentToken() {
    return getItem(CURRENT_TOKEN_KEY);
}



export function saveCurrentToken(token) {
    saveItem(CURRENT_TOKEN_KEY, token);
}


export function saveCurrentUserProjectId(userid, projectid) {
    saveItem(userid + '_prid', projectid);
}

export function saveCurrentUserProjectRole(userid, projectrole) {
    saveItem(userid + '_role', projectrole);
}

export function getCurrentUserProjectId(userid) {
    return getItem(userid + '_prid');
}

export function getCurrentUserProjectRole(userid) {
    return getItem(userid + '_role');
}


export function saveisFirstLogIn(userid) {
    saveItem(USER_LOGIN_CHECK_KEY + '_' + userid, userid);
}

export function saveUserInfo(userInfo) {
    saveItem(USER_INFO_KEY, userInfo);
}

export function getUserInfo() {
    return getItem(USER_INFO_KEY);
}

export function clearUserInfo() {
    localStorage.removeItem(USER_INFO_KEY);
    localStorage.removeItem(CURRENT_TOKEN_KEY);

    window.location.reload();
}
